import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Random from "../../assets/images/Random.jpg";

const Modal = ({
  isOpen,
  onClose,
  branches,
  selectedPickBranch,
  setSelectedPickBranch,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 210,
        left: -100,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        ref={modalRef}
        style={{
          background: "white",
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: "1rem",
          width: "90%",
          maxWidth: "700px",
          maxHeight: "50%",
          padding: "2%",
          border: "2px solid #000",
          borderRadius: "25px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
          overflow: "hidden",
        }}
      >
        <div style={{ overflowY: "auto", padding: "1rem" }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "1rem",
              paddingBottom: 2,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
            }}
          >
            Branch locations
          </Typography>
          {branches.map((branch, index) => (
            <label
              key={index}
              style={{ display: "block", marginBottom: "10px" }}
            >
              <input
                type="radio"
                value={branch.name}
                checked={selectedPickBranch === branch.name}
                onChange={() => setSelectedPickBranch(branch.name)}
                style={{
                  marginRight: "10px",
                  accentColor:
                    selectedPickBranch === branch.name ? "#D32F2F" : "initial",
                }}
              />
              <span
                style={{
                  color:
                    selectedPickBranch === branch.name ? "#D32F2F" : "black",
                  fontWeight:
                    selectedPickBranch === branch.name ? "bold" : "normal",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {branch.name}
              </span>
            </label>
          ))}
        </div>
        <div style={{ overflow: "hidden" }}>
          <img
            src={
              selectedPickBranch !== null
                ? branches.find((branch) => branch.name === selectedPickBranch)
                    ?.image || Random
                : Random
            }
            alt={
              selectedPickBranch !== null
                ? `Image of ${selectedPickBranch}`
                : "Default Branch Image"
            }
            style={{
              width: "100%",
              height: 250,
              objectFit: "cover",
              borderRadius: "20px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
