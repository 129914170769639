import React from "react";
import Slider from "react-infinite-logo-slider";
import Typography from "@mui/material/Typography";
import "./FooterOne.css";
import CHANGAN from "../../assets/images/CHANGAN.png";
import Hyundai from "../../assets/images/Hyundari.png";
import Jeep from "../../assets/images/Jeep.png";
import Kia from "../../assets/images/Kia.png";
import MAZDA from "../../assets/images/MAZDA.png";
import MG from "../../assets/images/MG.png";
import Mitsubishi from "../../assets/images/Mitsubishi.png";
import Nissan from "../../assets/images/Nissan.png";
import Suzuki from "../../assets/images/Suzuki.png";
import Toyota from "../../assets/images/Toyota.png";

const FooterOne = () => {
  return (
    <footer className="footer">
         <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                  fontFamily: "Poppins, sans-serif",
                  color:"#fff",
                  marginBottom:1
                }}
              >
               Our Fleet Brands
              </Typography>
      <Slider
        width="260px"
        duration={70}
        pauseOnHover={true}
        blurBorders={false}
        blurBoderColor={"#fff"}
      >
        <Slider.Slide>
          <img src={CHANGAN} alt="any" className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Hyundai} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Jeep} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Kia} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={MAZDA} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={MG} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Mitsubishi} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Nissan} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Suzuki} className="w-36" />
        </Slider.Slide>
        <Slider.Slide>
          <img src={Toyota} className="w-36" />
        </Slider.Slide>
      </Slider>
    </footer>
  );
};

export default FooterOne;
