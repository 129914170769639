import React from "react";
import "./LoadingScreen.css";

function LoadingScreen({ hidden }) {
  return (
    <div className={`loading-screen ${hidden ? "hide" : ""}`}>
<div class="loader">Loading...</div>

    </div>
  );
}

export default LoadingScreen;
