import React, { useEffect, useState } from "react";
import videoBg from "../../assets/videos/video.mp4";
import "./Main.css";
import Search from "../search/Search";
import HeaderOne from "../headerOne/HeaderOne";
import FooterOne from "../footerOne/FooterOne";

const Main = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`main ${loaded ? "loaded" : ""}`}>
      <div className="video-container">
        <video src={videoBg} autoPlay loop muted />
        <div className="overlay"></div>
      </div>

      <div className="main-content">
        <HeaderOne />
        <Search />
      </div>

      <div className="footer-container">
        <FooterOne />
      </div>
    </div>
  );
};

export default Main;

