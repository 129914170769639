import React, { useState, useEffect } from "react";
import "./App.css";
import Main from "./components/main/Main";
import LoadingScreen from "./components/Loading/LoadingScreen";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <LoadingScreen hidden={!loading} />
      {!loading && <Main />}
    </div>
  );
}

export default App;
