import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { IoSearchCircleSharp } from "react-icons/io5";
import Radio from "@mui/material/Radio";
import Sedan from "../../assets/images/sedan.png";
import Hatchback from "../../assets/images/hatchback.png";
import SUV from "../../assets/images/suv.png";
import PDO from "../../assets/images/pdo.png";
import Ghala from "../../assets/images/Ghala.jpg";
import AlKhuwair from "../../assets/images/AlKhuwair.jpg";
import Sohar from "../../assets/images/Sohar.jpg";
import Salalah from "../../assets/images/Salalah.jpg";
import Duqum from "../../assets/images/Duqum.jpg";
import "./Search.css";
import Modal from "./Modal";
import ModalTwo from "./ModalTwo";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 

const FeatureButton = ({ radioSelected, setRadioSelected, label, icon }) => (
  <Button
    variant="text"
    onClick={() => setRadioSelected(label)}
    sx={{
      textTransform: "none",
      backgroundColor: radioSelected === label ? "rgba(224,224,224,1)" : "#FFFFFF",
      color: radioSelected === label ? "#D32F2F" : "#000000",
      borderRadius: radioSelected === label ? "50px" : "50px",
      width: "100%",
      height: "40%",
      margin: 5,
      boxShadow: radioSelected === label ? "0px 0px 10px rgba(211,47,47,0.5)" : "none",
      transition: "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
      "&:hover": {
        backgroundColor: radioSelected !== null && radioSelected !== 0 ? "#9E9E9E" : "#E0E0E0",
        color: "#000000",
        borderRadius: radioSelected !== null ? "50px" : "0px",
      },
    }}
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      sx={{ width: "100%" }}
    >
      <Radio
        checked={radioSelected === label}
        value={label}
        color="error"
        sx={{ padding: "0 1px" }}
      />
      <Stack direction="column" alignItems="center" justifyContent="center">
        <img
          src={icon}
          style={{ width: 55, height: 55, top: -5 }} 
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "path_to_fallback_image";
          }}
        />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.8rem",
            fontFamily: "Poppins, sans-serif",
            paddingBottom: 2,
            marginTop: -1,
          }}
        >
          {label}
        </Typography>
      </Stack>
    </Stack>
  </Button>
);

const Search = () => {
  const [selected, setSelected] = useState(null);
  const [radioSelected, setRadioSelected] = useState(" ");
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const searchRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [selectedPickBranch, setSelectedPickBranch] = useState(null);
  const [selectedReturnBranch, setSelectedReturnBranch] = useState(null);
  const [openPickModal, setOpenPickModal] = useState(false);
const [openReturnModal, setOpenReturnModal] = useState(false);
  const branches = [
    { name: "Ghala", image: Ghala },
    { name: "Al Khuwair", image: AlKhuwair },
    { name: "Sohar", image: Sohar },
    { name: "Salalah", image: Salalah },
    { name: "Duqum", image: Duqum },
  ];

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseTwo = () => {
    setOpenTwo(false);
  };

  const handleOpenTwo = () => {
    setOpenTwo(true);
  };

  const handleOpenPickModal = () => {
    setOpenPickModal(true);
  };
  
  const handleClosePickModal = () => {
    setOpenPickModal(false);
  };
  
  const handleOpenReturnModal = () => {
    setOpenReturnModal(true);
  };
  
  const handleCloseReturnModal = () => {
    setOpenReturnModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSelected(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Stack
    direction="column"
    sx={{ width: "100%", padding: 1, alignItems: "center",paddingBottom:20 }}
  >
      <Stack direction="row" sx={{ width: "900px", overflowX: "hidden", height:"180px" }} alignItems="center" paddingBottom={4}>
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ width: "100%", height: "100%" }}
    >
      <FeatureButton
        radioSelected={radioSelected}
        setRadioSelected={setRadioSelected}
        label="Sedan"
        icon={Sedan}
      />
      <FeatureButton
        radioSelected={radioSelected}
        setRadioSelected={setRadioSelected}
        label="Hatchback"
        icon={Hatchback}
      />
      <FeatureButton
        radioSelected={radioSelected}
        setRadioSelected={setRadioSelected}
        label="SUV"
        icon={SUV}
      />
      <FeatureButton
        radioSelected={radioSelected}
        setRadioSelected={setRadioSelected}
        label="PDO"
        icon={PDO}
      />
    </Stack>
  </Stack>
  <Paper
      ref={searchRef}
      sx={{
        borderRadius: "50px",
        display: "flex",
        backgroundColor: selected !== null ? "rgba(224,224,224,1)" : "#FFFFFF",
        overflow: "hidden",
        width: "50%",
        height: 65,
        marginTop: -5,
      }}
      elevation={5}
    >
      <Stack direction="row" sx={{ width: "100%" }} alignItems="center">
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              backgroundColor: selected === 0 ? "#FFFFFF" : "transparent",
              color: selected === 0 ? "#D32F2F" : "#000000",
              borderRadius: selected === 0 ? "50px" : "0px",
              width: "100%",
              height: "100%",
              px: 2,
              boxShadow:
                selected === 0 ? "0px 0px 10px rgba(211,47,47,0.5)" : "none",
              transition:
                "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
              "&:hover": {
                backgroundColor:
                  selected !== null && selected !== 0 ? "#9E9E9E" : "#E0E0E0",
                color: "#000000",
                borderRadius: selected !== null ? "50px" : "0px",
              },
            }}
            onClick={() => {
              setSelected(0);
              setIsSearchExpanded(true);
              handleOpen();
            }}
          >
            <Stack direction="column" alignItems="flex-start">
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Pick-up
              </Typography>
              <Typography
                sx={{
                  color: "rgba(211,47,47,0.5)",
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedPickBranch !== null ? "bold" : "",
                }}
              >
                {selectedPickBranch || "Select location"}
              </Typography>
            </Stack>
          </Button>

          <Divider orientation="vertical" sx={{ backgroundColor: "#ffffff" }} />

          <Button
            variant="text"
            sx={{
              textTransform: "none",
              backgroundColor: selected === 1 ? "#FFFFFF" : "transparent",
              color: selected === 1 ? "#D32F2F" : "#000000",
              borderRadius: selected === 1 ? "50px" : "0px",
              width: "100%",
              height: "100%",
              px: 2,
              boxShadow:
                selected === 1 ? "0px 0px 10px rgba(211,47,47,0.5)" : "none",
              transition:
                "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
              "&:hover": {
                backgroundColor:
                  selected !== null && selected !== 1 ? "#9E9E9E" : "#E0E0E0",
                color: "#000000",
                borderRadius: selected !== null ? "50px" : "0px",
              },
            }}
            onClick={() => {
              setSelected(1);
              setIsSearchExpanded(true);
              handleOpenTwo();
            }}
          >
            <Stack direction="column" alignItems="flex-start">
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Return
              </Typography>
              <Typography
                sx={{
                  color: "rgba(211,47,47,0.5)",
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: selectedReturnBranch !== null ? "bold" : "",
                }}
              >
                {selectedReturnBranch || "Select location"}
              </Typography>
            </Stack>
          </Button>
          <Divider orientation="vertical" sx={{ backgroundColor: "#ffffff" }} />

          <Button
            variant="text"
            sx={{
              textTransform: "none",
              backgroundColor: selected === 2 ? "#FFFFFF" : "transparent",
              color: selected === 2 ? "#D32F2F" : "#000000",
              borderRadius: selected === 2 ? "50px" : "0px",
              width: "100%",
              height: "100%",
              px: 2,
              boxShadow:
                selected === 2 ? "0px 0px 10px rgba(211,47,47,0.5)" : "none",
              transition:
                "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
              "&:hover": {
                backgroundColor:
                  selected !== null && selected !== 2 ? "#9E9E9E" : "#E0E0E0",
                color: "#000000",
                borderRadius: selected !== null ? "50px" : "0px",
              },
            }}
            onClick={() => {
              setSelected(2);
            handleOpenPickModal()
              setIsSearchExpanded(true);
            }}
          >
            <Stack spacing={0} direction="column" alignItems="flex-start">
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                }}
              >
                Pick-up
              </Typography>
              <Typography
                sx={{
                  color: "rgba(211,47,47,0.5)",
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                }}
              >
                Select date
              </Typography>
            </Stack>
          </Button>
          <Divider orientation="vertical" sx={{ backgroundColor: "#ffffff" }} />

          <Button
            variant="text"
            sx={{
              textTransform: "none",
              backgroundColor: selected === 3 ? "#FFFFFF" : "transparent",
              color: selected === 3 ? "#D32F2F" : "#000000",
              borderRadius: selected === 3 ? "50px" : "0px",
              borderTopRightRadius: selected === 3 ? "0px" : "0px",
              borderBottomRightRadius: selected === 3 ? "0px" : "0px",
              width: "100%",
              height: "100%",
              px: 2,
              boxShadow:
                selected === 3 ? "0px 0px 10px rgba(211,47,47,0.5)" : "none",
              transition:
                "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
              "&:hover": {
                backgroundColor:
                  selected !== null && selected !== 3 ? "#9E9E9E" : "#E0E0E0",
                color: "#000000",
                borderRadius: selected !== null ? "50px" : "0px",
                borderTopRightRadius: selected !== null ? "0px" : "0px",
                borderBottomRightRadius: selected !== null ? "0px" : "0px",
              },
            }}
            onClick={() => {
              setSelected(3);
              handleOpenReturnModal()
              setIsSearchExpanded(true);
            }}
          >
            <Stack
              spacing={0}
              direction="column"
              alignItems="flex-start"
              left={-10}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Return
              </Typography>
              <Typography
                sx={{
                  color: "rgba(211,47,47,0.5)",
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Select date
              </Typography>
            </Stack>
          </Button>

          <Divider orientation="vertical" sx={{ backgroundColor: "#ffffff" }} />

          {isSearchExpanded ? (
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                backgroundColor: "#D32F2F",
                color: "#FFFFFF",
                borderRadius: "0px",
                width: "100%",
                height: "100%",
                px: 2,
                position: "relative",
                overflow: "hidden",
                transition:
                  "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
                opacity: 1,
                "&:hover": {
                  backgroundColor: "#9E9E9E",
                  color: "#ffffff",
                },
                "&:hover svg": {
                  color: "#D32F2F",
                },
              }}
              onClick={() => {
                setSelected(4);
                setIsSearchExpanded(true);
              }}
            >
              <IoSearchCircleSharp
                color="#ffffff"
                size={50}
                style={{
                  marginRight: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  position: "-webkit-sticky",
                  right: 0,
                  top: 0,
                }}
              />
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Search
              </Typography>
            </Button>
          ) : (
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                backgroundColor: "#FFFFFF",
                color: "#FFFFFF",
                borderRadius: "0px",
                width: "100%",
                height: "100%",
                px: 2,
                position: "relative",
                overflow: "hidden",
                transition:
                  "background-color 0.3s, color 0.3s, border-radius 0.3s, box-shadow 0.3s",
                opacity: 1,
                "&:hover": {
                  backgroundColor: "rgb(224,224,224,1)",
                  color: "#FFFFFF",
                },
                "&:hover svg": {
                  color: "#D32F2F",
                },
              }}
            >
              <IoSearchCircleSharp
                color="#D32F2F"
                size={50}
                className="searchIcon"
              />
            </Button>
          )}
      
        </Stack>
        <Modal
          isOpen={open}
          onClose={handleClose}
          branches={branches}
          selectedPickBranch={selectedPickBranch}
          setSelectedPickBranch={setSelectedPickBranch}
        />
        <ModalTwo
          isOpen={openTwo}
          onClose={handleCloseTwo}
          branches={branches}
          selectedReturnBranch={selectedReturnBranch}
          setSelectedReturnBranch={setSelectedReturnBranch}
        />        
      </Paper>
    </Stack>
  );
};

export default Search;
