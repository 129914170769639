import React from "react";
import logoImage from "../../assets/images/logo.png";
import "./HeaderOne.css";
import WorldIcon from "../../assets/icons/World.png";

const HeaderOne = () => {
  return (
    <div className="header">
      <img src={logoImage} alt="Logo" className="logo" />
      <div className="menu">
        <div className="menuTwo">
          <button className="menu-button">About</button>
          <button className="menu-button">Vehicle Fleet</button>
          <button className="menu-button">FAQ</button>
          <button className="menu-button">Contact</button>
        </div>
        <div className="language-icon" title="Change Language">
          <img src={WorldIcon} />
        </div>
      </div>
    </div>
  );
};

export default HeaderOne;
